import React from 'react';
import config from '../../config';

function CustomSignUp() {
  return (
    <div className="text-center">
      <div className="text-secondary font-weight-light">Interested in Borrowing?
        <a className="font-weight-normal" href={`${config.coreAppUrl}apply`}> Apply Now</a> for a loan.
      </div>
      <div className="text-secondary font-weight-light">Looking to Invest?
        <a className="font-weight-normal" href={`${config.coreAppUrl}lenders/signup`}> Sign up today</a>.
      </div>
    </div>
  );
}

export default CustomSignUp;
